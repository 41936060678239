body {
  font-family: Pretendard !important;
}

.ant-upload-wrapper {
  .ant-upload-drag {
    &:hover {
      border: 1px dashed #2c2c33 !important;
    }
  }
}
.tooltip-custom {
  .ant-tooltip-arrow {
    right: -142px;
    &::before {
      height: 7px;
      clip-path: path(
        "M14.0711 8.51471C14.962 8.51471 15.4081 7.43757 14.7782 6.8076L10.1213 2.15074C8.94975 0.97917 7.05025 0.97917 5.87868 2.15074L1.22183 6.8076C0.591867 7.43757 1.03803 8.51471 1.92894 8.51471L14.0711 8.51471Z"
      );
    }
  }
}
.tooltip-custom-media {
  .ant-tooltip-arrow {
    right: -173px;
    &::before {
      height: 7px;
      clip-path: path(
        "M14.0711 8.51471C14.962 8.51471 15.4081 7.43757 14.7782 6.8076L10.1213 2.15074C8.94975 0.97917 7.05025 0.97917 5.87868 2.15074L1.22183 6.8076C0.591867 7.43757 1.03803 8.51471 1.92894 8.51471L14.0711 8.51471Z"
      );
    }
  }
}

.apexcharts-legend-marker {
  border-radius: 6px;
  overflow: hidden;
}
