.ant-picker {
    border-radius: 12px;
    border: 1px solid #E1E1EB;
    box-shadow: none !important;
    input{
      font-family: Pretendard !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      color: #2C2C33 !important;
    }
    &::placeholder {
      color: #B4B4BD;
      font-size: inherit;
    }
    &:hover {
      border-color: #2C2C33 !important;
    }
    &:focus-within {
      border-color: #2C2C33 !important;
    }
  }
  
.react-date-custom{
  align-items: center;
  &.error{
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        border: 1px solid #ff4d4f;
      }
    }
  }
  .react-datepicker-wrapper{
    &.focus{
      .react-datepicker__input-container{
        border: 1px solid #2c2c33;
      }
    }
    .react-datepicker__input-container{
      display: flex;
      height: 100%;
      align-items: center;
      background-color: white;
      border-radius: 12px;
      border: 1px solid #E1E1EB;
      padding: 0px 16px;
      width: 100%;
      &:hover {
        border: 1px solid #2c2c33;
      }
      input{
        padding: 0 !important;
        outline: none !important;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        font-family: Pretendard;
        outline: none !important;
        &::placeholder{
          font-size: 16px;
        }
      }
    }
    .react-datepicker__view-calendar-icon{
      flex-direction: row-reverse;
      justify-content: space-between;
      .react-datepicker__calendar-icon{
        position: inherit !important;
        width:24px !important;
        height: 24px !important;
        padding:0px !important 
      }
    }
  }
  .react-datepicker-popper{
    .react-datepicker{
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 0px 24px 0px rgba(106, 107, 121, 0.12);
      border: 1px solid #E1E1EB;
      background: white;
      font-family: Pretendard;
      .react-datepicker__month-container{
        .react-datepicker__header{
          padding: 0px !important;
          background-color: white;
          border: none;
          display: flex;
          flex-direction: column;
          gap:12px;
          .react-datepicker__day-names{
            background-color: #F9F9FB;
            border-radius: 6px;
            display: flex;
            justify-content: space-between;
            .react-datepicker__day-name{
              font-size: 12px !important;
              color: #888896 !important;
              font-weight: 600 !important;
              width: 28px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .react-datepicker__month{
          margin: 0px;
          margin-top: 8px;
          .react-datepicker__week{
            display: flex;
            flex-wrap: wrap;
            .react-datepicker__day{
              display: flex;
              width: 28px;
              height: 24px;
              justify-content: center;
              align-items: center;
              font-size: 11px;
              font-weight: 400;
              &.react-datepicker__day--outside-month{
                color: #B4B4BD !important;
              }
              &.react-datepicker__day--keyboard-selected{
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2C2C33;
                color:#FFF;
              }
              &.react-datepicker__day--selected{
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2C2C33;
                color:#FFF;
              }
              &.react-datepicker__day--in-selecting-range{
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2C2C33;
                color:#FFF;
              }
              &.react-datepicker__day--in-range{
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #2C2C33;
                color:#FFF;
              }
            }
          }
        }
      }
    }
    >.react-datepicker__triangle{
      display: none !important;
    }
  }
}

.pr-react-datepicker-filter{
  .react-datepicker-wrapper{
    .react-datepicker__input-container{
      input{
        font-size: 14px;
        &::placeholder{
          font-size: 14px;
        }
      }
    }
  }
}