.report-table-medias {
  .ant-table-tbody > tr > td {
    border: none;
  }
  .ant-table-tbody > tr > td:last-child {
    border: 1;
  }
  .ant-table-thead > tr > th {
    color: #555560;
  }
}

.ant-pagination {
  margin-top: 32px !important;
  .ant-pagination-prev {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    stroke: #6f6f7b;
    cursor: pointer;
    &.ant-pagination-disabled {
      svg {
        stroke: #e1e1eb;
      }
    }
  }
  .ant-pagination-item {
    font-family: "Pretendard" !important;
    border-radius: 12px !important;
    background: transparent;
    a {
      font-size: 16px;
      font-weight: 500;
      color: #6f6f7b;
    }
    &.ant-pagination-item-active {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
        #2c2c33;
      > a {
        color: #fff !important;
      }
    }
  }
  .ant-pagination-next {
    stroke: #6f6f7b;
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    &.ant-pagination-disabled {
      svg {
        stroke: #e1e1eb;
      }
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    scrollbar-color: initial;
    border-radius: 12px;
  }
  .ant-table-container {
    table {
      border-collapse: separate;
      border-spacing: 0;
      border-radius: 12px;
      overflow: hidden;
      .ant-table-thead {
        tr {
          .ant-table-cell {
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            padding: 12px 16px;
            transition: none;
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              padding-right: 20px;
            }
          }
        }
        .ant-table-expanded-row {
          .ant-table-cell {
            padding: 8px 20px 20px 20px;
            background-color: #fff !important;
          }
        }
      }
    }
  }
}
.expanded-parent-column {
  .ant-table-cell {
    border: none !important;
  }
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > th:first-child {
  border-top-left-radius: 12px;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > th:last-child {
  border-top-right-radius: 12px;
}

.ant-table-wrapper
  .ant-table-container
  table
  > tbody
  > tr:last-child
  > td:first-child {
  border-bottom-left-radius: 12px;
}

.ant-table-wrapper
  .ant-table-container
  table
  > tbody
  > tr:last-child
  > td:last-child {
  border-bottom-right-radius: 12px;
}
