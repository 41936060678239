.pr-select {
  .ant-select {
    height: auto;
    .ant-select-selector {
      padding: 16px;
      border-radius: 12px;
      border: 1px solid #e1e1eb;
      font-family: Pretendard !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 100%;
      box-shadow: none !important;
      .ant-select-selection-search{
        inset-inline-start: 16px !important;
        inset-inline-end: 16px !important;
      }
      .ant-select-selection-item {
        color: #2c2c33;
      }
      .ant-select-selection-placeholder {
        color: #B4B4BD;
      }
      &:hover {
        border-color: #2c2c33 !important;
      }
      &:focus-within {
        border-color: #2c2c33 !important;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 8px;
  background: #fff;
  border: 1px solid #e1e1eb !important;
  box-shadow: 0px 0px 24px 0px rgba(106, 107, 121, 0.12);
  font-family: Pretendard !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  .rc-virtual-list {
    .rc-virtual-list-holder {
      .ant-select-item {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: #555560;
        &:hover {
          background-color: #f9f9fb !important;
          border-radius: 8px;
        }
        &.ant-select-item-option-selected {
          color: #2c2c33;
          background-color: #fff;
        }
        .ant-checkbox {
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: #2C2C33;
                    border-color: #2C2C33;
                }
            }
            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius:6px;
              &::after {
                inset-inline-start: 30%;
              }
            }
          }
      }
    }
  }
  .anticon {
    display: none;
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    padding: 0px !important;
    width: fit-content;
    background-color: #2A2C30 !important;
  }
}

.pr-select-multiple{
  .ant-select-multiple {
    .ant-select-selector {
      display: flex;
      flex-wrap: initial;
      justify-content: center;
      align-items: center;
      padding: 12.5px 16px;
      border-radius: 12px;
      box-shadow: none !important;
      .ant-select-selection-placeholder {
        padding-left: 5px !important;
        color: #B4B4BD;
        font-size: 16px;
      }
      .ant-select-selection-search{
        inset-inline-start: 16px !important;
        inset-inline-end: 16px !important;
      }
      .ant-select-selection-item {
        color: #2c2c33;
      }
      &:hover {
        border-color: #2c2c33 !important;
      }
      &:focus-within {
        border-color: #2c2c33 !important;
      }
      .ant-select-selection-overflow-item{
          .ant-select-selection-item{
              padding: 4px 8px;
              justify-content: center;
              align-items: center;
              margin-block: 0px !important ;
              height: 29px;
              .ant-select-selection-item-content{
                  font-size: 13px;
                  font-family: Pretendard !important;
                  color: #555560 !important;
              }
              .ant-select-selection-item-remove{
                  display: none;
              }
          }
      }
    }
  }
}


.pr-autocomplete {
  .ant-select {
    height: auto;
    .ant-select-selector {
      padding: 16px;
      border-radius: 12px;
      border: 1px solid #e1e1eb;
      font-family: Pretendard !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      height: 100%;
      box-shadow: none !important;
      .ant-select-selection-search{
        inset-inline-start: 16px !important;
        inset-inline-end: 16px !important;
        input{
          font-size: 14px;
        }
      }
      .ant-select-selection-item {
        color: #2c2c33;
      }
      .ant-select-selection-placeholder {
        color: #B4B4BD;
        font-size: 14px;
      }
      &:hover {
        border-color: #2c2c33 !important;
      }
      &:focus-within {
        border-color: #2c2c33 !important;
      }
    }
  }
}

.pr-select-input-custom {
  .ant-select{
    .ant-select-selector{
      .ant-select-selection-search{
        input{
          font-size: 14px;
          &::placeholder{
            font-size: 14px;
          }
        }
      }
      .ant-select-selection-placeholder{
        font-size: 14px;
      }
    }
  }
}