::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #E1E1EB;
    border: 2px solid #E1E1EB;
    border-radius: 4px;
  }