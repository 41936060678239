.pr-input {
  .ant-input {
    font-family: Pretendard !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #2C2C33;
    border-radius: 12px;
    border: 1px solid #E1E1EB;
    box-shadow: none !important;
    &::placeholder {
      color: #B4B4BD;
      font-size: inherit;
    }
    &:hover {
      border-color: #2C2C33 !important;
    }
    &:focus-within {
      border-color: #2C2C33 !important;
    }
  }
}
.pr-input-tags {
  .ant-input {
    font-family: Pretendard !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #2C2C33;
    border-radius: 12px;
    border: 1px solid #E1E1EB;
    box-shadow: none !important;
    &::placeholder {
      color: #B4B4BD;
      font-size: inherit;
    }
    &:hover {
      border-color: #2C2C33 !important;
    }
    &:focus-within {
      border-color: #2C2C33 !important;
    }
  }
}
.pr-textarea {
  .ant-input {
    font-family: Pretendard !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #2C2C33;
    border-radius: 12px;
    border: 1px solid #E1E1EB;
    box-shadow: none !important;
    padding: 0 16px;
    &::placeholder {
      color: #B4B4BD;
      font-size: inherit;
    }
    &:hover {
      border-color: #2C2C33 !important;
    }
    &:focus-within {
      border-color: #2C2C33 !important;
    }
  }
}

