.pr-modal-upload {
  .ant-modal-content {
    border-radius: 20px;
    box-shadow: none;
    padding: 24px;
    font-family: Pretendard !important;
  }
}

.find-email {
  .ant-modal-content {
    border-radius: 24px;
    padding: 24px;
  }
}

.find-email,
.termsPolicy {
  .ant-modal-close:hover {
    background: transparent;
  }
}

.termsPolicy {
  .ant-modal-content {
    border-radius: 24px;
    padding: 40px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    max-height: 56px !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1eb;
    border-radius: 50px;
    max-height: 56px !important;
  }
}
#toastContext {
  opacity: 0;
  transition: 1s;
  -webkit-transition: 1s;
}

#toastContext.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 1s;
  animation: fade 5s both;
  animation-delay: 3s;
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
