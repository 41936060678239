.pr-button {
  &.ant-btn-default {
    border: 1px solid #2c2c33;
    &:hover {
      color: #2c2c33;
      border-color: #2c2c33;
    }
  }
  &.ant-btn-default:disabled,
  .ant-btn-default.ant-btn-disabled {
    background: #e1e1eb !important;
    border-color: #e1e1eb !important;
    color: #888896 !important;
  }
  &.ant-btn-primary:disabled,
  .ant-btn-primary.ant-btn-disabled {
    background: #e1e1eb !important;
    border-color: #e1e1eb !important;
    color: #888896 !important;
  }
  &.ant-btn {
    box-shadow: none !important;
  }
}
