.vertical-steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .step-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: center;

    .step-content {
      padding-left: 8px;
      margin-left: 12px;
      height: 100%;

      .step-title {
        font-weight: 600;
        font-size: 14px;
        max-height: 26px;
        min-height: 26px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .step-content-2 {
      margin-left: 16px;

      .step-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px; /* 144.444% */
      }
    }

    .step-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;

      .large-dot {
        width: 8px;
        height: 8px;
        background-color: #B4B4BD;
        border-radius: 50%;
      }

      .active {
        width: 16px;
        height: 16px;
        background-color: #FFD5D5;
        border-radius: 50%;

        .large-dot {
          background-color: #FF6969;
        }
      }

      .small-dots-connector {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .small-dot {
          width: 4px;
          height: 4px;
          background-color: #EFEFF5;
          border-radius: 50%;
        }
      }
    }
  }
}
