.menu {
    .menu-item {
        svg{
            stroke: #888896;
        }
        &.active {
            svg{
                stroke: #EC2929;
            }
        }
    }
}