.report-table {
  .ant-table-thead > tr > th {
    background: #f9f9fb !important;
    color: #6f6f7b;
  }
  .ant-table-thead > tr:nth-child(2) {
    .ant-table-cell:nth-child(1),
    .ant-table-cell:nth-child(3),
    .ant-table-cell:nth-child(4),
    .ant-table-cell:nth-child(5),
    .ant-table-cell:nth-child(6),
    .ant-table-cell:nth-child(8),
    .ant-table-cell:nth-child(9) {
      border-inline-end: none !important;
    }
  }
  .ant-table-tbody > tr {
    .ant-table-cell:nth-child(3),
    .ant-table-cell:nth-child(5),
    .ant-table-cell:nth-child(6),
    .ant-table-cell:nth-child(7),
    .ant-table-cell:nth-child(8),
    .ant-table-cell:nth-child(10),
    .ant-table-cell:nth-child(11) {
      border-inline-end: none !important;
    }
  }
  .ant-table-tbody {
    .ant-table-row:last-child > td {
      border-top: 1px solid #ffb1b1;
    }
  }
}
